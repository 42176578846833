@use '@angular/material' as mat;
@import './assets/themes/theme-base';
@import './assets/themes/color-palettes';

@include mat.core();

$primary: mat.define-palette($mx-palette-teal, 500);
$accent: mat.define-palette($mx-palette-dark-gray, A200, A100, A400);
$warn: mat.define-palette($mx-palette-red, 600);

$simplicity-typography: mat.define-typography-config(
  $headline-1: mat.define-typography-level(6rem, 6rem, 500, 'Noto Serif, serif', -0.0625rem),
  $headline-2: mat.define-typography-level(3.75rem, 3.75rem, 500, 'Noto Serif, serif', -0.0625rem),
  $headline-3: mat.define-typography-level(2.25rem, 2.25rem, 500, 'Noto Serif, serif', normal),
  $headline-4: mat.define-typography-level(1.75rem, 1.75rem, 500, 'Noto Serif, serif', -0.03125rem),
  $headline-5: mat.define-typography-level(1.5rem, 1.5rem, 700, 'Noto Serif, serif', normal),
  $headline-6: mat.define-typography-level(1.25rem, 1.25rem, 500, 'Noto Serif, serif', normal),
  $subtitle-1: mat.define-typography-level(1.125rem, 1.125rem, 700, 'Lato, sans-serif', normal),
  $subtitle-2: mat.define-typography-level(1rem, 1rem, 700, 'Lato, sans-serif', normal),
  $body-1: mat.define-typography-level(1rem, 1rem, 400, 'Lato, sans-serif', normal),
  $body-2: mat.define-typography-level(1rem, 1rem, 400, 'Lato, sans-serif', normal),
  $caption: mat.define-typography-level(0.875rem, 0.875rem, 400, 'Lato, sans-serif', normal),
  $button: mat.define-typography-level(0.875rem, 0.875rem, 700, 'Lato, sans-serif', normal),
);

$simplicity-theme: mat.define-light-theme((
  color: (
    primary: $primary,
    accent: $accent,
    warn: $warn,
  ),
  typography: $simplicity-typography,
  density: 0,
));
@include mat.all-component-themes($simplicity-theme);
@include mat.typography-hierarchy($simplicity-theme);

h1, h2, h3, h4, h5, h6 {
  margin-block-start: 0;
  margin-block-end: 0;
}

.mat-headline-4,
.mat-headline-3,
.mat-headline-2,
.mat-headline-1,
.mat-small {
  color: var(--dk-gray);
  font-stretch: normal;
  font-style: normal;
}

@import 'remixicon/fonts/remixicon.css';

:root {
  --dk-gray: #1A1C1E;
  --dk-gray-2: #43474E;
  --med-gray: #505858;
  --med-gray-2: #dfe0e1;
  --soft-gray: #f5f5f5;
  --light-gray: #f5f5f5;
  --lighter-gray: #fcfcfc;
  --pearl: #cfd2cd;
  --light-pearl: #ededed;
  --light-teal: #ecf0ef;
  --tan: #f9f8f4;
  --offwhite: #f4f4f4;
  --white: #fff;
  --active: #0d8282;
  --info: #007bbd;
  --info-light: #e5f3fb;
  --success: #3cb959;
  --warn: #e6b00b;
  --warn-text: #846815;
  --error: #b32631;
  --error-light: #f8dde0;
  --Mackinac: P22Mackinac-Medium_6, serif;
  --Maax: Maax, Roboto, Franklin Gothic Medium, Tahoma, sans-serif;
  --Maax-Medium: Maax-Medium, sans-serif;
  --content-width: min(100% - 24px, 1200px);
}

// major element style
a {
  text-decoration: none;
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}

.spacer {
  flex: 1 1 auto;
}

.hidden {
  display: none !important;
}

.full-width {
  width: 100%;
}

// general use flex styling
.flex-row,
.mat-mdc-list-base.flex-row {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}

.flex-row-wrap,
.mat-mdc-list-base.flex-row-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.flex-row-inline,
.mat-mdc-list-base.flex-row-inline {
  display: inline-flex;
  flex-direction: row;
  box-sizing: border-box;
}

.flex-col,
.mat-mdc-list-base.flex-col {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.flex-col-inline,
.mat-mdc-list-base.flex-col-inline {
  display: inline-flex;
  flex-direction: column;
  box-sizing: border-box;
}

.flex-row-col-responsive {
  display: flex;
  box-sizing: border-box;
}

.flex-row-wrap-col-responsive {
  display: flex;
  box-sizing: border-box;
}

@media screen and (max-width: 599px) {
  .flex-row-col-responsive,
  .flex-row-wrap-col-responsive {
    flex-direction: column;
  }
}

@media screen and (min-width: 600px) {
  .flex-row-col-responsive {
    flex-direction: row;
  }
  .flex-row-wrap-col-responsive {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.flex-center-center {
  place-content: center center;
  align-items: center;
}

.flex-start-space-between {
  place-content: flex-start space-between;
  align-items: flex-start;
}

.flex-center-space-between {
  place-content: center space-between;
  align-items: center;
}

.flex-stretch-space-between {
  place-content: stretch space-between;
  align-items: stretch;
}

.flex-center-space-around {
  place-content: center space-around;
  align-items: center;
}

.flex-start-space-around {
  place-content: flex-start space-around;
  align-items: flex-start;
}

.flex-center-start {
  place-content: center flex-start;
  align-items: center;
}

.flex-start-center {
  place-content: flex-start center;
  align-items: flex-start;
}

.flex-start-start {
  place-content: flex-start flex-start;
  align-items: flex-start;
}

.flex-center-end {
  place-content: center flex-end;
  align-items: center;
}

.flex-end-start {
  justify-content: flex-start;
  align-items: flex-end;
}

.flex-start-end {
  justify-content: flex-end;
  align-items: flex-start;
}

.flex-end-space-between {
  place-content: flex-end space-between;
  align-items: flex-end;
}

.center {
  display: flex;
  justify-content: center;
}

.center-center {
  display: flex;
  place-content: center center;
  align-items: center;
}

@media screen and (max-width: 599px) {
  .center-xs {
    display: flex;
    justify-content: center;
  }
}

.flex-gap-4 {
  gap: 4px;
}

.flex-gap-6 {
  gap: 6px;
}

.flex-gap-8 {
  gap: 8px;
}

.flex-gap-12 {
  gap: 12px;
}

.flex-gap-16 {
  gap: 16px;
}

.flex-gap-18 {
  gap: 18px;
}

.flex-gap-20 {
  gap: 20px;
}

.flex-gap-30 {
  gap: 20px;
}

.flex-1 {
  flex: 1; // Same as flex: 1 1 0 (grow, shrink, basis 0)
}

.flex-2 {
  flex: 2;
}

.flex-grow {
  flex-grow: 1;
}

.hide-xs,
.hide-xs.mat-mdc-raised-button,
.hide-xs.mat-mdc-outlined-button {
  @media screen and (max-width: 599px) {
    display: none;
  }
}

.hide-gt-xs,
.hide-gt-xs.mat-icon,
.hide-gt-xs.mat-mdc-icon-button,
.hide-gt-xs.mat-expansion-panel-header,
.mat-mdc-list-base .hide-gt-xs.mat-mdc-list-item {
  @media screen and (min-width: 600px) {
    display: none;
  }
}

.hide-sm {
  @media screen and (max-width: 959px) {
    display: none;
  }
}

.hide-gt-sm {
  @media screen and (min-width: 960px) {
    display: none;
  }
}

// Nav bar styling
.horizontal-nav {
  .mdc-list {
    padding: 0;
  }

  .mdc-list-item__content {
    height: 1.625rem;
  }

  .mat-mdc-list-item {
    width: fit-content;
    padding: 0 min(30px, 2vw);
  }

  .mdc-list-item__primary-text {
    color: white !important;
  }
}

.generic-profile-avatar {
  background-image: url('./assets/generic-profile-avatar.png');
  background-size: cover;
}

// Search box styling
.search-box {
  @include mat.elevation(1);
  min-height: 64px;
  display: flex;
  align-items: center;
  padding-left: 24px;
  font-size: 20px;
}

.search-box mat-icon {
  padding-bottom: 10px;
}

// Loading indicator styling
.loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 56px;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

// Flex alignment
.flex-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

// Mat-Stepper styling (hiding it for mobile)
@media screen and (max-width: 959px) {
  .mat-horizontal-stepper-header-container {
    display: none !important;
  }
}

// Dialog styling - ideally this should be put at the component scope
mat-dialog-content {
  min-width: min(360px, 80vw);
}

.dialog-no-pad mat-dialog-container {
  padding: 0;
}

.dialog-warn-box {
  margin: 0 0 14px 0;
  width: 100%;
  padding: 5px;
}

.hold-dialog {
  max-width: 360px;
}

.dialog-body {
  margin-bottom: 14px;
}

.dialog-button-row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 40px 0 0 0;
}

.dialog-button-row > button {
  margin-right: 8px;
  flex: 1 1 45%;
}

.dialog-button-row > button:last-child,
a:last-child {
  margin-right: inherit;
}

// Save Footer styling
.save-footer {
  position: fixed;
  height: 100px;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 1em;
  z-index: 9;
}

.save-footer-content {
  height: 100%;
  max-width: var(--content-width);
  margin: 0 auto;
}

.save-button-group {
  width: 156px;
  height: 50px;
  text-align: center;
  margin: 10px;
  line-height: 30px;
}

// Button styling
.button-row-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.button-row-right > button {
  margin-right: 8px;
}

.button-row-right > button:last-child,
a:last-child {
  margin-right: inherit;
}

.button-row-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.button-row-left > button {
  margin-right: 8px;
}

.button-row-left > button:last-child,
a:last-child {
  margin-right: inherit;
}

.button-error-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.button-icon-right mat-icon {
  padding-left: 15px;
}

.button-icon-left mat-icon {
  padding-right: 15px;
}

// Dropdown styling
.remove-max-width {
  max-width: none !important;
}

.remove-padding .mat-mdc-menu-content:not(:empty) {
  padding: 0 !important;
}

// Pill styling
.mat-mdc-chip.mat-mdc-standard-chip .mdc-evolution-chip__text-label {
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Input field styling
mat-form-field.mat-mdc-form-field {
  margin-top: 4px;
  margin-bottom: 7px;
  width: 100%;
}

button.mat-mdc-button,
button.mat-mdc-outlined-button,
button.mat-mdc-raised-button {
  border-radius: 0;
}

// Barcode styling
ngx-barcode > .barcode > svg {
  width: 278px;
  height: 60px;
}

// Table styling
table {
  width: 100%;
}

tr.mat-mdc-row.mx-taller-row {
  height: 85px;
}

.mat-mdc-table .mat-mdc-no-data-row td {
  padding: 1rem;
}

.highlight {
  cursor: pointer;
}

td.table-row-title {
  color: var(--active);
}

.disabled-icon {
  color: var(--med-gray-2);
}

.page-body-wrapper {
  max-width: var(--content-width);
  margin: auto;
  padding: 1rem 0;
}

.empty-list {
  box-sizing: border-box;
  width: 100%;
  height: 82px;
  margin-top: 24px;
  padding: 30px;
  background-color: var(--light-gray);
}

// these can be replaced with tailwind styles eventually, but the table component needs a few global classes for column widths
.table-column-width-small {
  width: 6rem;
}

// pagination styling
div.mat-mdc-paginator-container {
  flex-direction: row-reverse;
  justify-content: space-between;
}

.mat-mdc-paginator-page-size {
  min-width: 11rem;
}

.mat-mdc-paginator-page-size-label {
  min-width: 6rem;
}

div.mat-mdc-paginator-range-label {
  min-width: 6rem;
  margin: 0;
}

// Items Out + Holds - desktop
@media screen and (max-width: 599px) {

  .mat-mdc-header-row {
    display: none;
  }

  td.mat-mdc-cell.cdk-column-checkbox,
  td.mat-cell.cdk-column-mobile,
  td.mat-cell.cdk-column-actions {
    padding: 12px 8px;
    vertical-align: top;
  }

  .page-body-wrapper {
    padding-top: 16px;
  }

  div.page-body-wrapper div.mat-headline-4 {
    margin-bottom: 16px;
  }

  .cdk-column-actions {
    width: 10%;

    div > :first-child {
      margin-bottom: 8px;
    }
  }

  div.mobile-table-entry > span,
  div.mobile-table-entry > rn-hold-status {
    margin: 2px 0;
  }
  div.mobile-table-entry > a {
    margin-bottom: 2px;
  }

  div.save-footer {
    height: min(80px, 10vh);
  }
}

div.mat-tab-labels {
  justify-content: space-evenly;
}
